import styled from '@emotion/styled'
import React from 'react'

import { Heading, LineContainer, List, Paragraph } from './../components'
import { mq } from './../utility/breakpoint'

export const LegalPageDe = ({ origin }) => {
  return (
    <SupportPageContainer>
      <Heading type="h4" spacing={{ top: 0.5, bottom: 0.4 }}>
        ALLGEMEINE INFORMATIONEN ZUR VERARBEITUNG PERSONENBEZOGENER DATEN
        <br /> Digitaler Brose Showroom
      </Heading>{' '}
      <Paragraph>
        <br />
      </Paragraph>
      <Paragraph>
        Mit den nachfolgenden Hinweisen möchten wir der Informationspflicht nach
        Artikel 13 der Datenschutzgrundverordnung („DSGVO“) nachkommen. <br />
        <br />
      </Paragraph>
      <Heading type="h5">Informationen gemäß Artikel 13 Abs. 1 DSGVO</Heading>
      <Paragraph>
        <strong>Verantwortlicher</strong>
        <br />
        Brose Fahrzeugteile SE & Co. Kommanditgesellschaft, Coburg
        <br />
        Max-Brose-Straße 1, 96450 Coburg
      </Paragraph>
      <Paragraph>
        <strong>Datenschutzbeauftragter</strong>
        <br />
        <a href="mailto:datenschutz@brose.com">datenschutz@brose.com</a>
      </Paragraph>
      <Paragraph>
        <strong>Datenkategorien</strong>
        <br />

        <ul>
          <li>Personenstammdaten</li>
          <li> Kontaktdaten</li>
          <li> Terminpräferenz </li>
          <li>IT-Nutzungsdaten</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>Verarbeitungszwecke</strong>
        <br />
        <ul>
          <li>
            Organisation und Durchführung virtueller Showroom Veranstaltungen
          </li>
          <li>
            Wenn Sie eingewilligt haben, weitere Kontaktaufnahme zur
            Produktvorstellung
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>Rechtsgrundlage</strong>
        <br />
        <ul>
          <li>das berechtigte Interesse unseres Unternehmens.</li>
          <li>Ihre Einwilligung.</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>Berechtigte Interessen:</strong>
        <br />
        Das berechtigte Interesse ist die Vorstellung von Produkten und
        innovativen Lösungen.
      </Paragraph>
      <Paragraph>
        <strong>Kategorien von Empfängern:</strong>
        <br />
        <ul>
          <li>Weitere Unternehmen der Brose Unternehmensgruppe</li>
          <li>Auftragsverarbeiter gem. Art. 28 DSGVO</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>
          Beabsichtigter Datentransfer in Drittländer/Internationale
          Organisationen:
        </strong>
        <br />
        Der Verantwortliche ist Teil der Brose Unternehmensgruppe (Brose). Brose
        eine weltweit agierende Gruppe von Unternehmen. Daher kann es zur
        Erfüllung obiger Zwecke notwendig sein, Ihre Daten an eine andere
        Gesellschaft der Brose Unternehmensgruppe weiterzugeben, auch solche
        außerhalb der EU/des EWR. Durch das Bestehen verbindlicher interner
        Datenschutzregelungen und durch die Vereinbarung der
        EU-Standardvertragsklauseln wird ein einheitliches Datenschutzniveau
        sichergestellt.
      </Paragraph>
      <Heading type="h5">Informationen gemäß Artikel 13 Abs. 2 DSGVO</Heading>
      <Paragraph>
        <strong>Speicherdauer</strong>
        <br />
        Ihre Daten werden nur solange gespeichert, wie wir diese zur Erfüllung
        o.g. Zwecke benötigen, bzw. bis zum Widerruf Ihrer Einwilligung.  
      </Paragraph>
      <Paragraph>
        <strong>Betroffenenrechte</strong>
        <br />
        <ul>
          <li>Auskunft (Art. 15)</li>
          <li>Berichtigung / Vervollständigung (Art. 16)</li>
          <li>Löschung (Art. 17)</li>
          <li>Einschränkung (Art. 18)</li>
          <li>Datenübertragng (Art. 20)</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>
          Sie können aus Gründen, die sich aus Ihrer besonderen Situation
          ergeben, Verarbeitungen aufgrund Art. 6 Abs. 1 e) oder f) DSGVO
          widersprechen (Art. 21). Nach erfolgtem Widerruf werden wir Ihre Daten
          nicht länger verarbeiten, es sei denn wir zwingende schutzwürdige
          Gründe für die Verarbeitung nachweisen können, die Ihre Interessen,
          Rechte und Freiheiten überwiegen, oder die Verarbeitung der
          Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient.
        </strong>
      </Paragraph>
      <Paragraph>
        <strong>Widerruf der Einwilligung:</strong>
        <br />
        Wenn die Verarbeitung auf Artikel 6 Absatz 1 Buchstabe a oder Artikel 9
        Absatz 2 Buchstabe a beruht, haben Sie das Recht, die Einwilligung
        jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der
        Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Ein
        Widerruf der Einwilligung kann per Mail an{' '}
        <a href="mailto:showroom@brose.com">showroom@brose.com</a> gerichtet
        werden.
      </Paragraph>
      <Paragraph>
        <strong>Beschwerderecht bei der Aufsichtsbehörde</strong>
        <br />
        Sollten Sie der Ansicht sein, dass Brose Ihren Bedenken oder Beschwerden
        nicht ausreichend nachgekommen ist, haben Sie das Recht, eine Beschwerde
        bei der zuständigen Aufsichtsbehörde einzureichen.
      </Paragraph>
      <Paragraph>
        <strong>
          Verpflichtung zur Bereitstellung der Daten / Folgen der
          Nichtbereitstellung:
        </strong>
        <br />
        Ohne Angabe Ihrer Kontaktdaten ist die Teilnahme an virtuellen
        Showroom-Veranstaltungen nicht möglich.
      </Paragraph>
      <Paragraph>
        <strong>Automatisierte Entscheidungsfindung / Profiling:</strong>
        <br />
        Findet nicht statt.
      </Paragraph>
    </SupportPageContainer>
  )
}
export const LegalPageEn = ({ origin }) => {
  return (
    <SupportPageContainer>
      <Heading type="h4" spacing={{ top: 0.5, bottom: 0.4 }}>
        GENERAL INFORMATION ABOUT PROCESSING OF PERSONAL DATA
        <br /> Digital Brose Showroom
      </Heading>{' '}
      <Paragraph>
        <br />
      </Paragraph>
      <Paragraph>
        With the following information, we would like to fulfil the obligation
        to provide information in accordance with Article 13 of the General Data
        Protection Regulation ("GDPR").
        <br />
        <br />
      </Paragraph>
      <Heading type="h5">
        Information pursant to Article 13 paragraph 1 GDPR:
      </Heading>
      <Paragraph>
        <strong>Controller</strong>
        <br />
        Brose Fahrzeugteile SE & Co. Kommanditgesellschaft, Coburg
        <br />
        Max-Brose-Straße 1, 96450 Coburg
      </Paragraph>
      <Paragraph>
        <strong>Data Protection Officer</strong>
        <br />
        <a href="mailto:datenschutz@brose.com">datenschutz@brose.com</a>
      </Paragraph>
      <Paragraph>
        <strong>Categories of personal data:</strong>
        <br />

        <ul>
          <li>Personnel master data </li>
          <li>Contact data </li>
          <li>Meeting preference</li>
          <li>System usage data</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>Purposes of processing</strong>
        <br />
        <ul>
          <li>Organisation and implementation of virtual showroom events</li>
          <li>
            Further contact for product presentation if you gave your consent
          </li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>Legal basis of processing</strong>
        <br />
        <ul>
          <li>The legitimate interests of Brose</li>
          <li>Your consent</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>
          Authorized interests of the Administrator (if point (f) of Article
          6(1) is applicable)
        </strong>
        <br />
        The legitimate interest is the presentation of products and innovative
        solutions.
      </Paragraph>
      <Paragraph>
        <strong>Categories of recipients</strong>
        <br />
        <ul>
          <li>Other legal entities of the Brose Group</li>
          <li>Processor (Art. 28)</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>
          The intention to transfer personal data to a third country or
          international organization
        </strong>
        <br />
        The controller is part of the Brose Group (Brose). Brose is a globally
        operating group of companies. It may also be necessary to transfer
        personal data to another company of the Brose group, including those
        outside the EU/EEA, in order to fulfill the above purposes. The
        existence of binding internal data protection regulations and the
        agreement of the EU standard contractual clauses ensure a uniform level
        of data protection.
      </Paragraph>
      <Heading type="h5">
        Information pursant to Article 13 paragraph 2 GDPR
      </Heading>
      <Paragraph>
        <strong>Time of personal data storage</strong>
        <br />
        Your data will only be stored for as long as we need it to fulfil the
        above-mentioned purposes or until you withdraw your consent.
      </Paragraph>
      <Paragraph>
        <strong>Rights of Data Subjects</strong>
        <br />
        <ul>
          <li>Access (Art. 15)</li>
          <li>Rectification (Art. 16)</li>
          <li>Erasure (Art. 17)</li>
          <li>Restriction (Art. 18)</li>
          <li>Data portability (Art. 20)</li>
        </ul>
      </Paragraph>
      <Paragraph>
        <strong>
          You may object to processing based on Art. 6 (1) e) or f) DSGVO for
          reasons arising from your particular situation (Art. 21). After the
          objection has been made, we will no longer process your data unless we
          can demonstrate compelling legitimate grounds for the processing which
          override your interests, rights and freedoms, or the processing serves
          the purpose of establishing, exercising or defending legal claims.
        </strong>
      </Paragraph>
      <Paragraph>
        <strong>Right to withdraw the consent</strong>
        <br />
        If the processing is based on point (a) of Article 6(1) or point (a) of
        Article 9(2), then the right to withdraw consent at any time is given,
        without affecting the lawfulness of processing based on consent before
        its withdrawal. A withdrawal of consent can be sent by email to{' '}
        <a href="mailto:showroom@brose.com">showroom@brose.com</a>.
      </Paragraph>
      <Paragraph>
        <strong>
          Right to lodge a complaint with the Supervisory Authority
        </strong>
        <br />
        If you feel that we have not adequately addressed your concerns or
        complaints, you have the right to lodge a complaint at the relevant
        supervisory authority.
      </Paragraph>
      <Paragraph>
        <strong>
          Statutory / contractual requirement to provide personal data and
          consequences of non-provision of the data
        </strong>
        <br />
        It is not possible to participate in a virtual showroom event without
        providing your contact details.
      </Paragraph>
      <Paragraph>
        <strong>Automated decision making / profiling</strong>
        <br />
        It is not carried out.
      </Paragraph>
    </SupportPageContainer>
  )
}

const SupportPageContainer = styled.div`
  h5 {
    margin-top: 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
  }

  ul {
    padding-top: 0;
    margin-top: 0;
    padding-left: 20px;
  }
`
