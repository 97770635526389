import React from 'react'
import { PageContainer, PageContainerOuter } from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { LegalPageEn, LegalPageDe } from '../content/legal'

const LegalPage = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params

  const origin = typeof window !== 'undefined' ? document.location.origin : ''

  return (
    <PageFrame {...params} pageName="legal">
      <PageContainerOuter>
        <PageContainer>
          {lang === 'de' ? (
            <LegalPageDe origin={origin} />
          ) : (
            <LegalPageEn origin={origin} />
          )}
        </PageContainer>
      </PageContainerOuter>
    </PageFrame>
  )
}

export default LegalPage
